@import "~@fluentui/react/dist/sass/_References.scss";
@import "~@fluentui/react/dist/sass/_Font.scss";
@import "~@fluentui/react/dist/sass/_Grid.scss";
@import "~@fluentui/react/dist/sass/_Responsive.scss";
@import "~@fluentui/react/dist/sass/variables/_Responsive.Variables.scss";
@import "ms-table.css";

$novatime-default-gray: rgb(129, 128, 120);
$novatime-light-gray: rgb(244, 244, 244);
$novatime-error-required-red: rgb(168, 0, 0);
$novatime-week-registration-border-color: rgb(234, 234, 234);
$novatime-week-registration-selected-color: rgb(16, 110, 190);
$novatime-week-registration-icon-hover-color: rgb(217, 217, 217);
$novatime-week-registration-row-hover-color: rgb(244, 244, 244);
$novatime-combo-box-placeholder-text: rgb(102, 102, 102);
$novatime-good-color: rgb(48, 143, 48);
$novatime-ok-color: rgb(146, 146, 48);
$novatime-bad-color: rgb(143, 48, 48);
$novatime-time-registration-hover-color: rgb(218, 218, 218);
$novatime-link-color: #2e7de1;
$novatime-month-registration-weekend: #f9f9f9;
$novatime-month-registration-holiday: #f9f9f9;
$novatime-month-registration-holiday-header: #b50a3e;
$novatime-month-registration-today-color: #7aafff;
$novatime-table-text-color: rgb(102, 102, 102);
$novatime-absencePeriod-background: #f2f2f2;
$novatime-lockedperiod-background: #e2f9ca;
$novatime-disabled-background: rgb(243, 242, 241);
$staticFilesPath: "../static";

body,
input,
textarea,
.input-range__label-container {
  font-family: "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

body {
  margin: 0;

  a {
    color: $novatime-link-color;
  }
}

.novatime-app-all-but-footer {
  min-height: calc(100vh - 50px);
}

.footer {
  height: 200px;
}

.height2em {
  height: 2em;
}

.height3em {
  height: 3em;
}

.height4em {
  height: 4em;
}

.expandable-component {
  .expandable-component-show-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    color: #069;
    font-family: "Segoe UI WestEuropean", "Segoe UI", -apple-system,
      BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  }

  &.collapsed {
    .expandable-component-children {
      display: none;
    }
  }
}

.novatime-timeregistration-form {
  .input-range {
    .input-range__label.input-range__label--min,
    .input-range__label.input-range__label--max,
    .input-range__label-container {
      display: none;
    }
  }

  .time-input-fields {
    .time-input-field {
      width: 50%;
      float: left;
    }
  }
}

.novatime-horizontal-rule {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  height: 1.5em;
  //    opacity: .5;
  margin-top: 2em;
  margin-bottom: 2em;

  @media (max-width: $ms-screen-max-md) {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &.text-align-center {
    text-align: center;
  }

  &.text-align-right {
    text-align: right;
  }

  &.text-align-left {
    text-align: left;
  }

  &:before {
    content: "";
    //      background: linear-gradient(to right, transparent, #818078, transparent);
    background: $novatime-default-gray;
    opacity: 0.1;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    padding-left: 0;
    line-height: 1.5em;
    color: $novatime-default-gray;
    background-color: white;
  }
}

.novatime-page-home {
  .novatime-jumbotron.novatime-home-jumbotron {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 225px 313px;
    background-color: rgb(52, 62, 72);
    background-image: url($staticFilesPath + "/images/Novataris_Background_logo_cutout.png");
    color: white;
    padding-top: 80px;
    padding-bottom: 100px;

    button {
      margin-top: 40px;
    }

    @media (max-width: $ms-screen-max-md) {
      background-size: 112px 160px;
      padding-top: 25px;
      padding-bottom: 54px;
      padding-right: 100px;

      button {
        margin-top: 20px;
      }
    }

    @media (min-width: $ms-screen-min-lg) {
      padding-right: 210px;
    }
  }
}

.novatime-time-registration-list-wrapper {
  .novatime-time-registration-list-toggle-buttons-container {
    margin-top: -30px;
    display: flex;

    .novatime-time-registration-list-toggle-button {
      width: 75px;
    }
  }
}

.novatime-time-registration-list .icon-column {
  padding-top: 4px;
}

.novatime-page-timeregistration {
  h2.missed-locked-time-registrations {
    text-align: center;
  }

  .new-time-registration-icon-standalone {
    margin-bottom: 2em;
    display: none;

    @media (min-width: $ms-screen-min-md) and (max-width: $ms-screen-max-lg) {
      display: block;
    }
  }

  .novatime-time-registration-list {
    margin-bottom: 4em;
  }

  .novatime-page-timeregistration-calendar-hr {
    margin-bottom: 0.5em;
    margin-top: 1em;
  }

  .novatime-close-buttons {
    text-align: right;
    margin-bottom: 1em;

    .ms-Button {
      margin-left: 1em;
      background-color: #f2f2f2;
      border: none;

      &.is-disabled {
        pointer-events: auto;
      }
    }
  }

  .novatime-registration-full-width {
    display: block;

    @media (max-width: $ms-screen-max-md) {
      display: none;
    }
  }

  .novatime-registration-mobile {
    display: none;

    @media (max-width: $ms-screen-max-md) {
      display: block;
    }

    .novatime-registration-item {
      padding: 10px;
      border-bottom: 1px solid $novatime-week-registration-border-color;

      &:last-child {
        border: none;
        margin-bottom: 15px;
      }

      &.selected {
        background: $novatime-time-registration-hover-color;
      }

      .ms-TextField-suffix {
        background: none;
      }
    }
  }
}

.novatime-page-projectsandroles {
  .project-resource-list {
    .dirty {
      .ms-DetailsRow {
        background-color: rgb(255, 238, 238);

        &:hover {
          background-color: rgb(242, 234, 234);
        }
      }
    }
  }
}

.novatime-page-resourcemanagement {
  .time-registration-list {
    margin-bottom: 4em;
  }

  .novatime-resourcemanagement-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: -2em;
    margin-bottom: 1em;

    .novatime-resourcemanagement-close-button {
      margin-right: 1em;
    }
  }
}

.novatime-mobile-panel {
  margin: 15px;
}

.novatime-modal-checkbox {
  margin-top: 0.5em;
}

.novatime-modal-default-button {
  margin-right: 1em;
}

.novatime-page-timeregistrationexport {
  .novatime-excelexport-button {
    text-align: right;
  }

  .novatime-horizontal-rule {
    margin-bottom: 0em;
  }

  .novatime-timeregexportresourcepicker-container {
    display: grid;
    grid-gap: 0px 0px;
    grid-template-columns: 90% 10%;
    height: 32px;

    .novatime-timeregexportresourcepicker-dropdown {
      grid-column: 1;
    }

    .novatime-timeregexportresourcepicker-removeall {
      grid-column: 2;

      button.ms-Button {
        color: rgb(166, 166, 166);
        padding: 0px;
        min-width: 30px;
        width: 100%;
        font-size: xx-small;
        height: 100%;

        &:hover {
          color: rgb(0, 69, 120);
        }
      }
    }
  }
}

.novatime-responsive-persona.ms-Persona {
  .ms-Persona-details {
    @media (max-width: $ms-screen-min-xl) {
      padding-right: 2px;
      padding-left: 2px;
    }
  }
}

.novatime-date-picker {
  .novatime-datepicker-left,
  .novatime-datepicker-right {
    width: 32px;

    button.ms-Button {
      color: rgb(166, 166, 166);
      background-color: white;

      &:hover {
        color: rgb(0, 69, 120);
      }
    }
  }

  .novatime-datepicker-left {
    float: left;
  }

  .novatime-datepicker-right {
    float: right;
  }

  .novatime-datepicker-center {
    width: calc(100% - 64px);
    margin-left: 32px;

    .ms-DatePicker {
      input.ms-TextField-field {
        padding-right: 32px;
      }
    }
  }
}

.novatime-jumbotron {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 6px;
  color: inherit;
  margin-bottom: 30px;
  background-color: #eee;
  box-sizing: border-box;
}

.novatime-week-registration {
  .novatime-week-registration-table {
    tr td,
    tr th {
      font-size: 15px;
    }

    tbody tr td div {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    tr td div.novatime-weekcellfield {
      transition: outline-color 0.2s ease;
      outline-style: solid;
      outline-width: 1px;
      outline-color: transparent;
    }

    tr td div.novatime-weekcellfield.underEdit {
      outline-color: $novatime-week-registration-selected-color;
      //            outline: 2px solid $novatime-week-registration-selected-color;
      //            box-sizing: border-box;
      &:hover {
        background-color: inherit !important;
      }
    }

    tr td.dirty {
      outline: 2px solid $novatime-error-required-red;
      outline-offset: -1px;
      box-sizing: border-box;

      &:hover {
        background-color: inherit !important;
      }
    }
  }

  .novatime-week-registration-mobile {
    font-size: 15px;

    @media (min-width: $ms-screen-min-lg) {
      display: none;
    }

    tbody {
      tr td.active span {
        font-weight: 500;
      }
    }

    .novatime-week-registration-accordion-item {
      .novatime-week-registration-accordion-item-header {
        border: 1px solid $novatime-week-registration-border-color;
        border-bottom: none;
        padding: 8px 10px;
        cursor: pointer;

        span {
          float: right;
          margin-right: 11px;
        }

        &.dirty {
          outline: 2px solid $novatime-error-required-red;
          outline-offset: -1px;
        }
      }

      .novatime-week-registration-accordion-item-body {
        display: none;
        padding-top: 1em;
        padding-bottom: 1em;

        .novatime-week-registration-table {
          tr {
            td:last-child {
              width: 118px;
            }

            &.dirty {
              td:last-child {
                outline: 2px solid $novatime-error-required-red;
                outline-offset: -1px;
              }
            }
          }
        }
      }

      &.selected {
        border-left: 2px solid $novatime-week-registration-selected-color;

        .novatime-week-registration-accordion-item-header {
          border-bottom: 1px solid $novatime-week-registration-border-color;
        }

        .novatime-week-registration-accordion-item-body {
          display: block;
        }
      }

      &:last-child {
        .novatime-week-registration-accordion-item-header {
          border-bottom: 1px solid $novatime-week-registration-border-color;
        }
      }
    }
  }

  .novatime-week-registration-fullsize {
    @media (max-width: $ms-screen-max-md) {
      display: none;
    }

    .novatime-week-registration-table {
      thead {
        tr:first-child {
          th {
            text-align: right;
            padding-bottom: 6px;
            vertical-align: top;
          }

          th:first-child {
            text-align: left;
          }

          th:not(:first-child):not(:last-child) {
            width: 100px;
          }

          th:last-child {
            text-align: center;
            vertical-align: middle;
          }
        }

        th.today {
          color: $novatime-month-registration-today-color;
        }

        th.holiday {
          background-color: $novatime-month-registration-weekend;
          color: $novatime-month-registration-holiday-header;
        }

        th.weekend {
          background-color: $novatime-month-registration-weekend;
        }
      }

      tbody {
        td.holiday {
          background-color: $novatime-month-registration-weekend;
        }

        td.weekend {
          background-color: $novatime-month-registration-weekend;
        }

        td.closed {
          background-color: $novatime-light-gray;
        }
        // every row in body except last row
        tr:not(:last-child) {
          // every cell in rows except and first and last column
          td:not(:first-child):not(:last-child) {
            padding-right: 0;
            padding-left: 0;

            &:hover {
              background-color: $novatime-week-registration-row-hover-color;
            }
          }

          &:hover {
            td:first-child {
              background-color: $novatime-week-registration-row-hover-color;
            }
          }
        }

        td.active span {
          font-weight: 500;
        }
        // every cell in body except first column
        tr td:not(:first-child) {
          text-align: right;
        }
        // last row in table body
        tr:last-child {
          td {
            padding-top: 1em;
            border-top: solid 1px $novatime-week-registration-border-color;
          }

          td:first-child {
            border-right: none;
          }
        }
        // first column in table body
        tr td:first-child {
          position: relative;
          border-right: solid 1px $novatime-week-registration-border-color;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 65px;

          .remove-project-icon {
            position: absolute;
            right: 5px;
            visibility: hidden;

            &:hover {
              background-color: $novatime-week-registration-icon-hover-color;
            }
          }

          .extend-period-alert-icon {
            position: absolute;
            right: 40px;
          }

          &:hover {
            .remove-project-icon {
              visibility: visible;
            }
          }
        }
        // last column in table body
        tr td:last-child {
          border-left: solid 1px $novatime-week-registration-border-color;
        }

        .callout-td {
          display: inline-block;

          .ms-Button {
            background-color: #f2f2f2;
            border: none;
          }
        }
      }
    }
  }
}

.novatime-locale-selector {
  .novatime-locale-selector-show-Callout-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: $novatime-table-text-color;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    font-family: "Segoe UI WestEuropean", "Segoe UI", -apple-system,
      BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  }

  .novatime-locale-selector-main-button {
    img {
      height: 16px;
    }
  }
}

.novatime-locale-selector-clear {
  float: right;
  color: red;
  cursor: pointer;
}

.novatime-locale-selector-callout {
  padding: 1em;

  .ms-ChoiceFieldGroup label {
    text-align: center;
    padding-bottom: 1.2em;
  }
}

.invalid-timeregistration-callout {
  padding: 1em;

  .header p {
    font-size: 16;
  }
}

.novatime-weekcellfield {
  &.disabled {
    background-color: $novatime-disabled-background;
  }

  .ms-Button--icon:first-child {
    margin-left: 10px;
  }

  .new-time-registration-icon {
    float: left;
    z-index: 10;
    margin-top: 3px;

    @media (min-width: $ms-screen-min-md) and (max-width: $ms-screen-max-lg) {
      display: none !important;
    }

    &:hover {
      background-color: $novatime-week-registration-icon-hover-color;
    }
  }

  .invalid-icon {
    margin-top: 5px;
    float: left;
    z-index: 10;
  }

  .novatime-hourfield {
    position: relative;
  }

  .ms-TextField {
    width: 100%;

    .ms-TextField-fieldGroup {
      background-color: transparent;

      input {
        width: 100%;
        text-align: right;
        font-size: 16px;

        @media (min-width: $ms-screen-min-md) and (max-width: $ms-screen-max-md) {
          font-size: 15px;
        }

        border: 1px solid transparent;
        padding-right: 10px;
      }

      i {
        right: 65px;
        bottom: 7px;
      }

      i.fixposition {
        position: absolute;
        bottom: 1px;
      }
    }

    &.is-disabled .ms-TextField-fieldGroup input {
      color: gray;
    }
  }

  .spinner {
    width: 20px;
    float: right;
    padding-right: 12px;
  }
}

.novatime-project-browser-modal {
  max-width: 1200px;
  min-width: 300px;
  padding: 2em;
  max-height: 80% !important;
}

.scrollable-content-modal {
  max-height: fit-content !important;
}

.novatime-project-browser {
  .novatime-project-browser-search-field {
    margin-bottom: 25px;

    input {
      font-size: 15px;
    }
  }

  .novatime-project-browser-list {
    .novatime-project-browser-item {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: rgb(228, 228, 228) 1px solid;

      .novatime-project-browser-item-checkbox {
        margin-bottom: 5px;

        span {
          font-size: 15px;
        }
      }

      .novatime-project-browser-item-description {
        margin-left: 27px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    margin-bottom: 25px;
  }

  .novatime-project-browser-buttonbar {
    text-align: right;

    button {
      margin-left: 10px;
    }
  }
}

.novatime-month-registration {
  .novatime-month-registration-table {
    tr {
      th {
        font-size: 11px;
      }

      td {
        font-size: 12px;
        padding: 0;
      }
    }

    tr th.weekend {
      background-color: $novatime-month-registration-weekend;
    }

    tr td {
      &:first-child {
        padding: 5px;
      }

      &.inactive {
        font-style: italic;

        .ms-Persona-primaryText {
          color: $novatime-default-gray;
        }
      }

      &.weekend {
        background-color: $novatime-month-registration-weekend;
      }

      &.today {
        border-left: $novatime-month-registration-today-color 1px solid;
      }

      &.holiday {
        background-color: $novatime-month-registration-weekend;
      }

      &.locked {
        background-color: $novatime-lockedperiod-background;
        border-top: 3px solid $novatime-lockedperiod-background;
        border-bottom: 3px solid $novatime-lockedperiod-background;
      }

      &.first-day-locked {
        border-left: 3px solid $novatime-lockedperiod-background;
      }

      &.last-day-locked {
        border-right: 3px solid $novatime-lockedperiod-background;
      }

      &.absencePeriod-approved {
        background-color: $novatime-absencePeriod-background;
      }

      &.absencePeriod-pending {
        background: repeating-linear-gradient(
          90deg,
          white,
          white 7px,
          $novatime-absencePeriod-background 10px,
          $novatime-absencePeriod-background 17px
        );
      }

      &.locked.absencePeriod-approved,
      &.locked.absencePeriod-pending {
        border-top: 3px solid $novatime-lockedperiod-background;
        border-bottom: 3px solid $novatime-lockedperiod-background;
      }
    }

    tr th.today {
      color: $novatime-month-registration-today-color;
    }

    tr th.today {
      border-left: $novatime-month-registration-today-color 1px solid;
    }

    tr th.holiday {
      background-color: $novatime-month-registration-weekend;
    }

    tr th.holiday {
      color: $novatime-month-registration-holiday-header;
    }

    @for $i from 0 through 100 {
      tr td .valid-#{$i} {
        @if ($i <= 50) {
          color: mix($novatime-ok-color, $novatime-good-color, $i * 2);
        } @else {
          color: mix($novatime-ok-color, $novatime-bad-color, (100-$i) * 2);
        }
      }
    }

    tr td .locked {
      font-style: italic;
    }

    tr td:not(:first-child) {
      .novatime-hourfield {
        cursor: pointer;
        position: relative;
        padding: 0 5px;
      }

      &.underEdit {
        outline: 2px solid $novatime-week-registration-selected-color;
        box-sizing: border-box;
      }
    }
  }

  .novatime-month-registration-mobile {
    font-size: 15px;

    @media (min-width: $ms-screen-min-lg) {
      display: none;
    }

    .novatime-month-registration-accordion-item {
      .novatime-month-registration-accordion-item-header {
        border: 1px solid $novatime-week-registration-border-color;
        border-bottom: none;
        padding: 8px 10px;
        cursor: pointer;

        span {
          float: right;
          margin-right: 11px;
        }
      }

      .novatime-month-registration-accordion-item-body {
        display: none;
        padding-top: 1em;
        padding-bottom: 1em;

        .novatime-month-registration-table {
          tr td:last-child {
            width: 118px;
          }
        }
      }

      &.selected {
        border-left: 2px solid $novatime-week-registration-selected-color;

        .novatime-month-registration-accordion-item-header {
          border-bottom: 1px solid $novatime-week-registration-border-color;
        }

        .novatime-month-registration-accordion-item-body {
          display: block;
        }
      }

      &:last-child {
        .novatime-month-registration-accordion-item-header {
          border-bottom: 1px solid $novatime-week-registration-border-color;
        }
      }
    }
  }

  .novatime-month-registration-fullsize {
    @media (max-width: $ms-screen-max-md) {
      display: none;
    }

    .novatime-month-registration-table {
      thead tr:first-child {
        th {
          text-align: right;
          padding-bottom: 1em;
          vertical-align: top;
        }

        th:first-child {
          text-align: left;
        }

        th:not(:first-child):not(:last-child) {
          width: 100px;
        }
      }

      tbody {
        // every row in body except last row
        tr:not(:last-child) {
          font-weight: 500;

          &:hover {
            td:first-child {
              background-color: $novatime-week-registration-row-hover-color;
            }

            td:not(:first-child) {
              font-weight: bold;
            }
          }
        }
        // every cell in body except first column
        tr td:not(:first-child) {
          text-align: right;

          .outer-spinner {
            width: 20px;
            float: right;
            padding-right: 12px;
          }
        }
        // last row in table body
        tr:last-child {
          td {
            padding-top: 1em;
            border-top: solid 1px $novatime-week-registration-border-color;
          }

          td:first-child {
            border-right: none;
          }
        }
        // first column in table body
        tr td:first-child {
          position: relative;
          border-right: solid 1px $novatime-week-registration-border-color;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 45px;

          &:hover {
            .remove-project-icon {
              visibility: visible;
            }
          }
        }
        // last column in table body
        tr td:last-child {
          border-left: solid 1px $novatime-week-registration-border-color;
        }
      }
    }
  }
}

.notifications-wrapper .notifications-tr {
  .notification {
    padding: 0 !important;
    height: 51px;
  }
}

.novatime-month-hour-field {
  text-align: right;
  padding-bottom: 2px;
  vertical-align: top;
  padding-right: 10px;
  padding-left: 10px;

  &::after {
    display: block;
    content: "00,00";
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.novatime-month-registration
  td.weekend
  .novatime-month-hour-field.absencePeriod-pending {
  background: repeating-linear-gradient(
    90deg,
    $novatime-month-registration-weekend,
    $novatime-month-registration-weekend 7px,
    $novatime-absencePeriod-background 10px,
    $novatime-absencePeriod-background 17px
  );
}

.novatime-registration-week {
  table {
    font-size: 14px;
  }

  .form-control.editor.edit-text {
    width: 100%;
  }
}

.icon-color-red {
  color: red;
}

.icon-color-yellow {
  color: darkgoldenrod;
}

input[type="datetime"] {
  padding: 4px;
  margin-bottom: 10px;
  display: block;
  border: 0px;
  outline: none;
  border-radius: 2px;
}

.novatime-container {
  max-width: 1400px;
  margin: 0 auto;
}

.novatime-centered {
  margin: 0 auto;
}

.novatime-header {
  .title-bar {
    margin: 1.5em;
    margin-left: 17px;

    .novataris-logo {
      cursor: pointer;
      max-width: 200px;
    }
  }

  .novatime-header-navbar {
    background-color: inherit;

    button.ms-Button {
      background-color: inherit;
    }

    .ms-CommandBar {
      background-color: #f2f2f2;

      button {
        border: none;

        .ms-Button-textContainer {
          font-size: 15px;
        }

        i {
          font-size: 17px;
        }

        &.active {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      .ms-CommandBar-primaryCommands {
        .ms-CommandBarItem {
          margin-right: 10px;
        }
      }

      .ms-CommandBar-sideCommands {
        .ms-CommandBarItem .ms-Persona {
          .ms-Persona-primaryText {
            font-size: 15px !important;
          }
        }
      }
    }
  }

  .novatime-header-menu-wrapper {
    background-color: rgb(242, 242, 242);
  }
}

.novatime-loading-indicator {
  margin-top: 12px;
  //    max-width: 30px;
  //    width: 90px;
  height: 20px;
  padding-right: 16px;
  padding-top: 11px;

  .novatime-loading-icon {
    height: 20px;
    float: right;
    margin-right: 20px;
  }

  .novatime-loading-button {
    float: right;
    margin-top: -1px;

    &:hover {
      background-color: rgb(234, 234, 234);
      cursor: pointer;
      outline: rgb(234, 234, 234) 5px solid;
    }

    i {
      line-height: 22px;
      font-size: 20px;
    }
  }
}

.novatime-loading-indicator-modal {
  min-width: 1000px;
  max-width: 1200px;
  overflow-y: scroll;
  height: 80%;
  margin-bottom: 1em;
  padding: 1em;
  margin-bottom: 1em;
  background-color: rgb(39, 40, 34);
}

.novatime-loading-indicator-callout {
  min-width: 300px;
  max-width: 600px;

  .novatime-loading-indicator-callout-header {
    padding: 1em;
    text-align: center;

    .novatime-loading-indicator-callout-title {
      margin: 0;
      font-size: 21px;
      font-weight: 100;
      color: #333333;
    }
  }

  .novatime-loading-indicator-callout-inner {
    padding: 0 1em 1em 1em;

    .novatime-loading-indicator-callout-content {
      .novatime-horizontal-rule {
        margin-top: 1em;
        margin-bottom: 1em;
      }

      .failedRequest {
        &:hover {
          background-color: $novatime-week-registration-icon-hover-color;
          outline: $novatime-week-registration-icon-hover-color 5px solid;
        }

        cursor: pointer;
      }
    }

    .novatime-loading-indicator-callout-footer {
      padding-top: 1em;
      text-align: right;
    }
  }
}

.novatime-resource-persona {
  &.inactive .ms-Persona-primaryText {
    color: gray;
    font-style: italic;
  }
}

.novatime-resource-picker {
  position: relative;

  i.indicator {
    position: absolute;
    top: 9px;
    right: 9px;
  }
}

.novatime-base-picker-simple {
  position: relative;

  i.indicator {
    position: absolute;
    bottom: 8px;
    right: 34px;
  }

  .placeholder {
    color: $novatime-combo-box-placeholder-text;
    position: absolute;
    bottom: 1px;
    left: 14px;
  }
}

.novatime-date-picker {
  .error-message {
    font-size: 12px;
    font-weight: 400;
    color: $novatime-error-required-red;
    margin: 0;
    padding-top: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .picker-and-placeholder {
    position: relative;

    .placeholder {
      color: $novatime-combo-box-placeholder-text;
      pointer-events: none;
      position: absolute;
      top: 30px;
      left: 12px;
    }
  }
}

button.delete {
  margin-right: 8px;
  background: #8b0000;
  color: #ffffff;

  &:hover {
    background: #7e0000;
  }

  &:disabled {
    background: gray;
  }
}

.label-required:after {
  content: " *";
  color: $novatime-error-required-red;
}

.novatime-resource-fieldset {
  border: 1px solid $novatime-default-gray;

  legend {
    @extend .label-required;
  }

  p {
    font-style: italic;
  }
}

.main-content {
  margin-top: 2em;
  margin-right: 1em;
  margin-left: 1em;
}

.message-board-wrap {
  max-width: 300px;
  background: #ccc;
  padding: 10px;
  border: #000000;
  border-radius: 5px;
}

.user-log-wrap {
  max-width: 300px;
  background: #00fa9a;
  padding: 10px;
  border: #000000;
  border-radius: 5px;
}

.account-wrap {
  margin: 30px;
  max-width: 300px;
  background: #b0e0e6;
  padding: 10px;
  border: #000000;
  border-radius: 5px;
}

.account-temp {
  display: none;
}

.indented-block {
  margin-left: 2em;
}

.registration-week-component {
  position: relative;
  height: 350px;
  width: 60%;

  .registration-week-overlay {
    /*width: calc(100%*3.5);*/
    border: black 1px solid;
    background: rgb(6, 129, 192);
    overflow-x: visible;
    border-radius: 3px;
    color: white;

    .right {
      float: right;
      padding-right: 3px;
    }

    .left {
      float: left;
      padding-left: 3px;
    }
  }
}

.novatime-project-callout {
  @media (min-width: $ms-screen-min-md) {
    width: 350px;
  }

  .novatime-project-callout-body {
    margin: 15px;
  }
}
// Dropdowns in a callout have their display set to block, which messes up the layout on small screens.
// This fixes that issue.
.ms-Dropdown-Item {
  display: flex !important;
}

.novatime-hovercard {
  width: 100%;
  height: 100%;
  font-size: 15px;

  tr {
    &:hover {
      font-weight: bold;
    }

    td:not(:first-child) {
      text-align: right;
    }
  }
}

.calendar-layout-component {
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;

  .calendar-row-component,
  .calendar-row-header-component {
    height: calc(100% / 8);
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #ddd;

    > .calendar-cell-component:last-child {
      border-right: none;
      margin-right: -1px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .calendar-cell-component {
    float: left;
    width: calc(100% / 14);
    box-sizing: border-box;
    border-right: 1px solid #ddd;
    height: 100%;
    text-align: center;
  }
}

.clearfix {
  overflow: auto;
}
.persona-permission {
  min-width: 100px;
  padding-bottom: 5px;
}

.novatime-absencePeriod-comment-form {
  .ms-ActivityItem {
    margin-bottom: 1em;
  }
}

.novatime-text-bottom-formular {
  display: inline-block;
  color: lightgrey;
}

.novatime-form-buttonbar {
  margin-top: 1em;
}

.novatime-form-modeltimestamps {
  margin-top: 1em;
  color: lightgrey;
  span {
    color: lightgrey;
  }
}

.novatime-report-headline {
  background-color: $novatime-light-gray;
  padding: 0.5em;
  margin: 0;
}

.novatime-report-container {
  padding-top: 1em;
  padding-bottom: 1em;
}

.novatime-report-warning-line {
  padding: 0.5em;
  margin: 0;
  color: $novatime-bad-color;

  .novatime-report-warning-line-spacer {
    display: inline-block;
    padding-left: 1em;

    i {
      vertical-align: bottom;
    }
  }
}

.novatime-custompicker-label-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  text-align: left;

  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
}

.novatime-custompicker-mainbutton {
  width: 100%;
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;

  .novatime-custompicker-mainbutton-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    text-align: left;
    width: 100%;
  }

  .novatime-custompicker-mainbutton-number {
    color: rgb(237, 235, 233);
    font-weight: bold;
    border-radius: 3px;
    height: 20px;
    background-color: rgb(0, 120, 212);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.novatime-custompicker-callout {
  width: 500px;
  height: auto;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  float: right;

  .novatime-custompicker-searchbox {
    margin: 5px;
  }

  .novatime-custompicker-checkboxesprimary-container {
    height: 100%;

    .novatime-custompicker-groupofcheckboxes-divider {
      height: 1px;
      background-color: rgb(237, 235, 233);
      margin-bottom: 5px;
    }
  }

  .novatime-custompicker-groupofcheckboxes-container {
    padding-bottom: 10px;

    .novatime-custompicker-groupofcheckboxes-header {
      color: rgb(0, 120, 212);
      font-size: 14px;
      font-weight: 600;
    }

    .novatime-custompicker-groupofcheckboxes {
      padding-left: 5px;
      padding-right: 5px;
      background-color: rgb(255, 255, 255);

      .novatime-custompicker-checkbox {
        padding-top: 0px;
        padding-right: 8px;
        padding-bottom: 0px;
        padding-left: 8px;

        .novatime-custompicker-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 435px;
        }
      }
    }
  }

  .novatime-custompicker-button {
    margin: 5px;
    margin-top: 0px;
    color: rgb(0, 120, 212);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 490px;

    .novatime-custompicker-button-lefttext {
      float: left;
      width: 25%;
      text-align: left;
      font-size: 12px;
      color: rgb(129, 128, 120);
    }

    .novatime-custompicker-button-centertext {
      float: left;
      width: 50%;
      text-align: center;
      font-weight: 600;
    }

    .novatime-custompicker-button-righttext {
      float: left;
      width: 25%;
      text-align: right;
      font-size: 12px;
      color: rgb(129, 128, 120);
    }
  }
}
