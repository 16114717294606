.ms-Table {
  font-family: 'Segoe UI WestEuropean', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.ms-Table--fixed {
  table-layout: fixed;
}

.ms-Table tr,
.ms-Table-row {
  display: table-row;
  line-height: 30px;
  font-weight: 300;
  font-size: 12px;
  color: #333333;
}

.ms-Table tr.is-selected,
.ms-Table-row.is-selected {
  background-color: #b3d6f2;
}

.ms-Table tr.is-selected .ms-Table-rowCheck,
.ms-Table-row.is-selected .ms-Table-rowCheck {
  background-color: #0078d7;
}

.ms-Table tr.is-selected .ms-Table-rowCheck::before,
.ms-Table-row.is-selected .ms-Table-rowCheck::before {
  display: none;
}

.ms-Table tr.is-selected .ms-Table-rowCheck::after,
.ms-Table-row.is-selected .ms-Table-rowCheck::after {
  content: '\E73A';
  color: #ffffff;
}

.ms-Table th,
.ms-Table td,
.ms-Table-cell {
  display: table-cell;
  padding: 0 10px;
}

.ms-Table thead th,
.ms-Table-head {
  font-weight: 300;
  font-size: 11px;
  color: #666666;
}

.ms-Table thead td,
.ms-Table thead th,
.ms-Table thead .ms-Table-cell,
.ms-Table thead .ms-Table-rowCheck,
.ms-Table-head td,
.ms-Table-head th,
.ms-Table-head .ms-Table-cell,
.ms-Table-head .ms-Table-rowCheck {
  font-weight: normal;
  text-align: left;
  border-bottom: 1px solid #eaeaea;
}

.ms-Table-rowCheck {
  display: table-cell;
  width: 20px;
  position: relative;
  padding: 0;
}

.ms-Table-rowCheck::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'FabricMDL2Icons';
  font-style: normal;
  font-weight: normal;
  speak: none;
  content: '\E739';
  color: #a6a6a6;
  font-size: 12px;
  position: absolute;
  left: 4px;
  top: 1px;
}

.ms-Table--selectable tr:hover,
.ms-Table--selectable .ms-Table-row:hover {
  background-color: #f4f4f4;
  cursor: pointer;
  outline: 1px solid transparent;
}

@media screen and (-ms-high-contrast: active) {
  .ms-Table-row.is-selected .ms-Table-rowCheck {
    background: none;
  }

  .ms-Table-row.is-selected .ms-Table-rowCheck::before {
    display: block;
  }
}